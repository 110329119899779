import React, { useContext, useState } from "react";
import "./Step2.css";
import { Box, Modal, RadioGroup } from "@mui/material";
import { FluxoAddProviderContext } from "./FluxoAddProvider";
import { toast } from "react-toastify";
import { AuthContext } from "../../../../providers/AuthProvider";
import iconLOading from "../../../../assets/Frame 11.png";
import { useFormik } from "formik";
import * as yup from "yup";
import {
  DateTimePicker,
  LocalizationProvider,
  TimePicker,
} from "@mui/x-date-pickers";
import { AdapterMoment } from "@mui/x-date-pickers/AdapterMoment";
import arrow from "../../../../assets/Vector.png";
import InputMask from "react-input-mask";
import unmask from "../../../../utils/unmask";

const Step2 = () => {
  const { user, checkComplete, updatePartnerDetail } = useContext(AuthContext);
  const {
    formSchedules,
    navigate,
    id,
    handleInput,
    insertParamIntoMessage,
    setCurrentStep,
    handleAddPhoneNumber,
    phoneNumbers,
    handleDeletePhoneNumber,
    loading,
    loadingCsv,
    handleFileChange,
    getCsv,
    saveLoading,
    setPhone,
    phone,
    countryCode,
    setCountryCode,
    countryFlags,
    handleDigitAPI,
  } = useContext(FluxoAddProviderContext);
  const [modal, setModal] = useState(false);

  const handlePhoneChange = (value) => {
    // Extrai o código do país
    const match = value.match(/^\+(\d{1,3})/); // Busca por + seguido de 1 a 3 dígitos
    if (match) {
      const code = match[1];
      setCountryCode(code);
    }
    setPhone(value);
  };

  function extractCountryCode(phoneNumber) {
    const match = phoneNumber.match(/^\+(\d+)/); // Encontra o código do país após o "+" no início
    return match ? match[1] : null; // Retorna o código ou null se não encontrar
  }

  function extractPhoneWithoutCountryCode(phoneNumber) {
    // Remove espaços extras e mantém somente o '+' e números
    const sanitizedNumber = phoneNumber.trim();

    // Encontra o código do país e separa o restante
    const match = sanitizedNumber.match(/^\+\d+\s?(.*)/);
    return match ? match[1] : null; // Retorna o restante do número ou null se não encontrar
  }

  return (
    <>
      {loading ? (
        <div className="containerLoadingCheck">
          <div className="loadingDescription">
            <img src={iconLOading} alt="" />
            <div>Conectando...</div>
          </div>
        </div>
      ) : (
        <div className="containerStep2Auto">
          <div className="cardContent">
            <div style={{ display: "flex", gap: "20px" }}>
              <div
                style={{
                  flex: 1,
                  display: "flex",
                  gap: "20px",
                  flexDirection: "column",
                }}
              >
                <div className="containerInput" style={{ lineHeight: "1px" }}>
                  <div className="title" style={{ marginBottom: "0px" }}>
                    Tipo de disparo
                  </div>
                  {/* <div className="subTitleInput">
                Selecione o tipo de bancária utilizado
              </div> */}
                  <RadioGroup
                    row
                    value={formSchedules.values.triggerType}
                    style={{
                      height: "56px",
                      display: "flex",
                      gap: "20px",
                    }}
                    onClick={(e) => {
                      if (e.target.value)
                        formSchedules.setFieldValue(
                          "triggerType",
                          e.target.value
                        );
                    }}
                  >
                    <label className="checkbox-container">
                      <div
                        className="checkmarkBackground"
                        style={{ borderRadius: "15px" }}
                      >
                        <input
                          type="checkbox"
                          value="scheduled"
                          checked={
                            formSchedules?.values?.triggerType === "scheduled"
                          }
                        />
                        <span
                          className="checkmark"
                          style={{ borderRadius: "15px" }}
                        ></span>
                      </div>
                      <div>Agendado</div>
                    </label>
                    <label className="checkbox-container">
                      <div
                        className="checkmarkBackground"
                        style={{ borderRadius: "15px" }}
                      >
                        <input
                          type="checkbox"
                          checked={
                            formSchedules?.values?.triggerType === "manual"
                          }
                          value="manual"
                        />
                        <span
                          className="checkmark"
                          style={{ borderRadius: "15px" }}
                        ></span>
                      </div>
                      <div>Manual</div>
                    </label>
                  </RadioGroup>
                  {formSchedules.touched.triggerType &&
                  formSchedules.errors.triggerType ? (
                    <div>{formSchedules.errors.triggerType}</div>
                  ) : null}
                </div>

                {formSchedules.values.triggerType === "scheduled" && (
                  <>
                    <div
                      className="containerInput"
                      style={{ lineHeight: "1px" }}
                    >
                      <div className="title" style={{ marginBottom: "5px" }}>
                        Data e Hora do disparo
                      </div>
                      <div className="subTitleInput">
                        Selecione a data e hora para o envio da mensagem.{" "}
                      </div>
                      <LocalizationProvider dateAdapter={AdapterMoment}>
                        <DateTimePicker
                          value={formSchedules?.values?.triggerTime}
                          onChange={(newValue) =>
                            formSchedules.setFieldValue("triggerTime", newValue)
                          }
                          // format="HH[h] mm[m]"
                          className="custom-timepickerAuto"
                        />
                      </LocalizationProvider>
                    </div>
                  </>
                )}

                <div
                  className="containerInput"
                  style={{ marginTop: "15px", lineHeight: "1px" }}
                >
                  <div className="title" style={{ marginBottom: "5px" }}>
                    Numeros de telefones
                  </div>
                  <div className="subTitleInput">
                    Adicione os números de telefone para os quais as mensagens
                    serão enviadas.
                  </div>

                  <div className="containerINputsPhones">
                    <div
                      style={{
                        display: "flex",
                        alignItems: "center",
                        gap: "8px",
                      }}
                    >
                      {/* Bandeira do país */}
                      <img
                        src={
                          countryFlags[countryCode] ||
                          "https://flagcdn.com/w320/un.png"
                        } // Bandeira ou padrão
                        alt="Country flag"
                        style={{ width: "24px", height: "16px" }}
                      />

                      {/* Campo de entrada com máscara */}
                      <InputMask
                        className="inputField"
                        mask={"+99 99 99999-9999"}
                        value={phone}
                        onChange={(e) => handlePhoneChange(e.target.value)}
                        placeholder="Digite o telefone"
                        autoComplete="new-password"
                      >
                        {(inputProps) => <input {...inputProps} type="text" />}
                      </InputMask>
                    </div>
                    <button
                      onClick={handleAddPhoneNumber}
                      className="addParameterButton"
                      style={{ width: "40px", height: "100%" }}
                    >
                      +
                    </button>
                  </div>

                  <div className="subTitleInput" style={{ marginTop: "10px" }}>
                    Numeros adicionados:
                  </div>

                  <div
                    style={{ display: "flex", flexWrap: "wrap", gap: "20px" }}
                  >
                    {phoneNumbers.map((number, index) => (
                      <>
                        <div
                          key={index}
                          style={{
                            display: "flex",
                            alignItems: "center",
                            gap: "5px",
                          }}
                        >
                          <div
                            style={{
                              display: "flex",
                              alignItems: "center",
                              gap: "8px",
                            }}
                          >
                            {/* Bandeira do país */}
                            <img
                              src={
                                countryFlags[extractCountryCode(number)] ||
                                "https://flagcdn.com/w320/un.png"
                              } // Bandeira ou padrão
                              alt="Country flag"
                              style={{ width: "24px", height: "16px" }}
                            />

                            <div>{extractPhoneWithoutCountryCode(number)}</div>
                          </div>

                          <button
                            onClick={() => handleDeletePhoneNumber(number)}
                            style={{
                              width: "20px",
                              height: "20px",
                              backgroundColor: "#dd3545",
                              color: "#fff",
                              fontSize: "13px",
                            }}
                          >
                            X
                          </button>
                        </div>

                        <div style={{ borderRight: "1px solid #232c45" }}></div>
                      </>
                    ))}
                  </div>
                </div>
              </div>

              <div style={{ flex: 1 }}>
                <div className="containerInput" style={{ lineHeight: "1px" }}>
                  <div className="title" style={{ marginBottom: "5px" }}>
                    Disparo de 24hrs
                  </div>
                  {/* <div className="subTitleInput">
                Selecione o tipo de bancária utilizado
              </div> */}
                  <RadioGroup
                    row
                    value={formSchedules.values.isFullDayDispatch}
                    style={{
                      height: "56px",
                      display: "flex",
                      gap: "20px",
                    }}
                    onClick={(e) => {
                      if (e.target.value)
                        formSchedules.setFieldValue(
                          "isFullDayDispatch",
                          e.target.value === "true" ? true : false
                        );
                    }}
                  >
                    <label className="checkbox-container">
                      <div
                        className="checkmarkBackground"
                        style={{ borderRadius: "15px" }}
                      >
                        <input
                          type="checkbox"
                          checked={formSchedules?.values?.isFullDayDispatch}
                          value={true}
                        />
                        <span
                          className="checkmark"
                          style={{ borderRadius: "15px" }}
                        ></span>
                      </div>
                      <div>Sim</div>
                    </label>

                    <label className="checkbox-container">
                      <div
                        className="checkmarkBackground"
                        style={{ borderRadius: "15px" }}
                      >
                        <input
                          type="checkbox"
                          value={false}
                          checked={!formSchedules?.values?.isFullDayDispatch}
                        />
                        <span
                          className="checkmark"
                          style={{ borderRadius: "15px" }}
                        ></span>
                      </div>
                      <div>Não</div>
                    </label>
                  </RadioGroup>
                  {formSchedules.touched.isFullDayDispatch &&
                  formSchedules.errors.isFullDayDispatch ? (
                    <div>{formSchedules.errors.isFullDayDispatch}</div>
                  ) : null}
                </div>

                {!formSchedules.values.isFullDayDispatch && (
                  <>
                    <div
                      className="containerInput"
                      style={{ lineHeight: "1px" }}
                    >
                      <div className="title" style={{ marginBottom: "5px" }}>
                        {" "}
                        Hórario de inicio dos disparos
                      </div>
                      <div className="subTitleInput">
                        Selecione o horario de inicio para o envio da mensagem.
                      </div>
                      <LocalizationProvider dateAdapter={AdapterMoment}>
                        <TimePicker
                          value={formSchedules.values.dispatchStart}
                          onChange={(newValue) =>
                            formSchedules.setFieldValue(
                              "dispatchStart",
                              newValue
                            )
                          }
                          // format="HH[h] mm[m]"
                          className="custom-timepickerAuto"
                        />
                      </LocalizationProvider>
                    </div>

                    <div
                      className="containerInput"
                      style={{ lineHeight: "1px" }}
                    >
                      <div
                        className="title"
                        style={{ marginBottom: "5px", marginTop: "15px" }}
                      >
                        {" "}
                        Hórario final dos disparo
                      </div>
                      <div className="subTitleInput">
                        Selecione o horario de témino para o envio da mensagem.
                      </div>
                      <LocalizationProvider dateAdapter={AdapterMoment}>
                        <TimePicker
                          value={formSchedules.values.dispatchEnd}
                          onChange={(newValue) =>
                            formSchedules.setFieldValue("dispatchEnd", newValue)
                          }
                          // format="HH[h] mm[m]"
                          className="custom-timepickerAuto"
                        />
                      </LocalizationProvider>
                    </div>
                  </>
                )}

                <div
                  className="containerInput"
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    justifyContent: "flex-end",
                    marginTop: "30px",
                  }}
                >
                  <div className="containerINputsPhones" style={{ flex: 1 }}>
                    <label
                      htmlFor="csvInput"
                      style={{
                        flex: 1,
                        height: "40px",
                        backgroundColor:
                          formSchedules.values.fileCsv && "#CE3C3C",
                        color: formSchedules.values.fileCsv && "#fff",
                      }}
                    >
                      {formSchedules.values.fileCsv
                        ? "Remover CSV"
                        : "Adicionar CSV"}
                    </label>

                    {formSchedules.values.fileCsv ? (
                      <input
                        id="csvInput"
                        type="button"
                        onClick={() =>
                          formSchedules.setFieldValue("fileCsv", null)
                        }
                        style={{ display: "none" }}
                      />
                    ) : (
                      <input
                        id="csvInput"
                        type="file"
                        accept=".csv"
                        onChange={handleFileChange}
                        style={{ display: "none" }}
                      />
                    )}
                  </div>

                  <div className="containerINputsPhones" style={{ flex: 1 }}>
                    <button
                      onClick={getCsv}
                      style={{ flex: 1, height: "40px", color: "#0b2744" }}
                    >
                      {loadingCsv ? "Baixando" : "Baixar modelo CSV"}
                    </button>
                  </div>
                </div>
              </div>
            </div>

            <div style={{ display: "flex", gap: "20px" }}>
              <button
                onClick={() => setCurrentStep("step1")}
                className="buttonBackCheck"
              >
                Voltar
              </button>

              <button
                onClick={() => {
                  formSchedules.handleSubmit();
                  navigate(-1);
                }}
                className="buttonNextAUto"
              >
                Salvar
              </button>
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default Step2;
