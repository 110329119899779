import React, { useContext, useEffect, useState } from "react";
import iconLOading from "../../../../../assets/Frame 11.png";

import iconWhats from "../../../../../assets/Vector (21).png";
import "./Step3.css";
import {
  Box,
  Modal,
  Typography,
} from "@mui/material";
import ReactInputMask from "react-input-mask";
import { CheckContext } from "./CheckProvider";
import { Loader } from "../../../../../components/Loader";
import { toast } from "react-toastify";
import { AuthContext } from "../../../../../providers/AuthProvider";
import api from "../../../../../utils/api";
import unmask from "../../../../../utils/unmask";

const Step3 = () => {
  const { user, checkComplete, updateUser } = useContext(AuthContext);
  const { setCurrentStep } = useContext(CheckContext);
  const [qrLoading, setQrLoading] = useState(true);
  const [qrCode, setQrCode] = useState("");
  const [phone, setPhone] = useState("");
  const [checkConnected, setCheckConnected] = useState(false);
  const [tempPhone, setTempPhone] = useState(phone);
  const [modal, setModal] = useState(false);

  function isMobileDevice() {
    return window.innerWidth <= 768;
  }

  let width = isMobileDevice() ? 350 : 515;

  const checkWhatsConeccted = async () => {
    try {
      const { data } = await api.get("/partners/partners/wpp_connected", {
        headers: {
          "Cache-Control": "no-cache",
        },
      });
      setCheckConnected(data.wppConnected);
    } catch (error) {}
  };

  const CountdownTimer = () => {
    const [seconds, setSeconds] = useState(60);

    if (seconds === 0 && !checkConnected) {
      setModal(false);
      setQrCode("");
      toast.error("Tempo expirado! Por favor, gere um novo QR Code.");
    } else if (checkConnected) {
      setModal(false);
      setQrCode("");
      toast.success("Numero do atendente conectado com sucesso!");
      if (user.partnerDetailId) {
        checkComplete(user.partnerDetailId);
        setCurrentStep('step4');
      } else {
        checkComplete(user.id);
        setCurrentStep('step4');
      }
    }

    useEffect(() => {
      const countdownInterval = setInterval(() => {
        setSeconds((prevSeconds) => prevSeconds - 1);
      }, 1000);

      const checkInterval = setInterval(async () => {
        await checkWhatsConeccted();
        if (checkConnected) {
          clearInterval(countdownInterval);
          clearInterval(checkInterval);
        }
      }, 8000);

      return () => {
        clearInterval(countdownInterval);
        clearInterval(checkInterval);
      };
    }, [checkConnected]);

    const minutes = Math.floor(seconds / 60);
    const remainingSeconds = seconds % 60;

    return (
      <div style={{ display: "flex", justifyContent: "center" }}>
        <p
          style={{
            color: seconds <= 10 && "red",
            margin: 0,
            fontFamily: ['"Roboto"', '"Helvetica"', '"Arial"', "sans-serif"],
            fontWeight: 500,
            fontSize: "1.25rem",
            lineHeight: 1.6,
            letterSpacing: "0.0075em",
            alignSelf: "flex-start",
            marginTop: "15px",
            marginBottom: "15px",
            textAlign: "center",
            width: "100%",
          }}
        >
          Tempo restante: {minutes}:{remainingSeconds < 10 ? "0" : ""}
          {remainingSeconds}
        </p>
      </div>
    );
  };

  const getQRCode = async (item) => {
    try {
      const { data } = await api.get("/partners/auth_whatsapp", {
        responseType: "arraybuffer",
      });

      if (data) {
        const blob = new Blob([data], { type: "image/jpeg" });
        const url = URL.createObjectURL(blob);
        console.log(url);
        
        if (url) {
          setQrCode(url);
        } else {
          setQrCode("QRCodeError");
          throw Error("No URL");
        }
      }
    } catch (error) {
      toast.error("Ocorreu um erro ao tentar gerar o QR Code de validação.");
      if (error?.message !== "No URL") {
        setQrCode("GeneralError");
      }
      // setModal(false);
    } finally {
      setQrLoading(false);
      // setModal(false);
      updateUser(item);
    }
  };

  const updatePhone = async () => {
    setQrLoading(true);
    setModal(true);
    try {
      if (phone) {
        const { data } = await api.put(`/partners/partners/${user.id}`, {
          data: {
            attributes: {
              service_number: unmask(phone),
            },
          },
        });
        if (data) {
          getQRCode(data);
        }
      }
    } catch (error) {
      //console.log(error);
      toast.error("Ocorreu um erro ao tentar atualizar o número cadastrado.");
      setModal(false);
    }
  };

  return (
    <div className="containerStep3Check">
      <Modal
        open={modal}
        onClose={() => {
          setModal(false);
          setQrLoading(true);
        }}
      >
        <Box
          style={{
            background: "linear-gradient(180deg, #f1fcf4 0%, #FFFFFF 100%)",
            borderRadius: "20px",
          }}
          sx={{
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            width: width,
            // height: 200,
            p: 2.5,
          }}
        >
          <div>
            {qrLoading ? (
              // <Loader size={150} />
              <div className="containerLoadingStep3" >
              <div className="loadingDescription">
                <img src={iconLOading} alt="" />
                <div>Gerando seu QR Code, aguarde um momento...</div>
              </div>
            </div>
            ) : (
              <div style={{ display: "flex", flexDirection: "column" }}>
                <Typography
                  sx={{
                    margin: 0,
                    fontFamily: [
                      '"Roboto"',
                      '"Helvetica"',
                      '"Arial"',
                      "sans-serif",
                    ],
                    fontWeight: 500,
                    fontSize: "1.25rem",
                    lineHeight: 1.6,
                    letterSpacing: "0.0075em",
                    alignSelf: "flex-start",
                    marginTop: "15px",
                    marginBottom: "15px",
                    textAlign: "center",
                    width: "100%",
                  }}
                >
                  {qrCode === "QRCodeError" || qrCode === "GeneralError" ? (
                    <Typography
                      fontSize={24}
                      fontWeight={600}
                      color={"#539d65"}
                    >
                      Erro
                    </Typography>
                  ) : (
                    "Leia o QRCode abaixo para realizar a validação"
                  )}
                </Typography>
                {/* {console.log(qrCode)} */}
                {qrCode === "QRCodeError" || qrCode === "GeneralError" ? (
                  <Typography
                    textAlign={"center"}
                    style={{ marginBottom: "25px" }}
                  >
                    {qrCode === "QRCodeError"
                      ? "Ocorreu um erro ao gerar o QRCode, verifique se o número registrado está disponível para vinculação."
                      : "Ocorreu um erro ao gerar o QRCode, por favor tente novamente."}
                  </Typography>
                ) : (
                  <img
                    src={qrCode}
                    alt="QR Code"
                    style={{ borderRadius: "20px" }}
                  />
                )}

                {/* {(!qrCode === "QRCodeError" || !qrCode === "GeneralError") && ( */}

                <CountdownTimer />
                {/* )} */}
              </div>
            )}
          </div>
        </Box>
      </Modal>
      <div className="title">Vinculando o WhatsApp</div>
      <div className="cardContent">
        <div>
          Para garantir uma experiência fluida, use um{" "}
          <strong>número exclusivo</strong> para o seu assistente. Assim, ele
          pode responder rapidamente todas as mensagens recebidas!
        </div>

        <div>
          <strong>Atenção:</strong> O assistente vai responder qualquer mensagem
          que for enviada para este número.
        </div>

       <div className="containerInputPhone">
        <div>Insira aqui o número de telefone do seu assistente</div>
       <ReactInputMask
          mask={
            tempPhone.replace(/\s/g, "").length > 13
              ? "(99) 9 9999-9999"
              : "(99) 9999-99999"
          }
          value={phone}
          onChange={(e) => setPhone(e.target.value)}
          maskChar={""}
          className="input"
        />
       </div>


       <button onClick={() => updatePhone()}>
          <img src={iconWhats} alt="" />
          Vincular conta de WhatsApp
        </button>

        <button onClick={() => setCurrentStep('step2')} className="buttonBackCheck">
          Voltar
        </button>

      </div>
    </div>
  );
};

export default Step3;
