import React, { useContext, useState } from "react";
import "./Step5.css";
import { Box } from "@mui/material";
import { CheckContext } from "./CheckProvider";
import { AuthContext } from "../../../../../providers/AuthProvider";
import { useNavigate } from "react-router-dom";
import iconLOading from "../../../../../assets/Frame 11.png";

const Step5 = () => {
  const { user,setCheckList } = useContext(AuthContext);
  const { updateSettings, setCurrentStep } = useContext(CheckContext);

  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);

  const checkWpp = user?.wppConnected ? true : false;
  const checkGoogle = user?.connectedWithGoogle ? true : false;

  const updatSetings = async (url) => {
    setLoading(true);
    try {
      await updateSettings();
      navigate(url);
    } catch (erro) {
    } finally {
      setLoading(false);
    }
  };

  return (
    <>
      {loading ? (
        <div className="containerLoadingCheck">
          <div className="loadingDescription">
            <img src={iconLOading} alt="" />
            <div>Estamos atualizando seu cadastro...</div>
          </div>
        </div>
      ) : (
        <div className="containerStep5Check">
          {checkGoogle && <div className="title">Calendário Vinculado!</div>}

          <div
            className="containerStatusCheck"
            style={{ marginBottom: "27px", display :'flex' }}
          >
            <Box
              sx={{
                cursor: "pointer",
                ":hover": {
                  opacity: "55%",
                },
              }}
              className={`${checkWpp}`}
            >
              <div className="cicle"></div>
              <div>WHATSAPP</div>
            </Box>

            <Box
              sx={{
                cursor: "pointer",
                ":hover": {
                  opacity: "55%",
                },
              }}
              className={`${checkGoogle}`}
            >
              <div className="cicle"></div>
              <div>CALENDÁRIO</div>
            </Box>
          </div>

          <div className="cardContent">
            <div>
              Pronto para começar! Você tem{" "}
              <strong>100.000 tokens grátis.</strong> Mantenha o atendimento
              contínuo adquirindo um plano mensal de tokens.
            </div>

            <div>
              <strong>Atenção:</strong> Se os seus tokens acabarem seu
              assistente para de responder!
            </div>

            <button onClick={() => {
                localStorage.setItem("@sacgpt:checkWizard", 'true');
                setCheckList({
                  profileFilled:user?.profileFilled,
                  partnerDetailsFilled: user?.partnerDetailsFilled,
                  activePlan:user?.activePlan,
                  wizard: true,
                });
                navigate(`/financeiro/step1`)
              }}>
              Escolher um plano de Tokens
            </button>

            <button
              onClick={() => setCurrentStep("step4")}
              className="buttonBackCheck"
            >
              Voltar
            </button>

            <div
              className="nextButton"
              onClick={() => {
                localStorage.setItem("@sacgpt:checkWizard", 'true');
                setCheckList({
                  profileFilled:user?.profileFilled,
                  partnerDetailsFilled: user?.partnerDetailsFilled,
                  activePlan:user?.activePlan,
                  wizard: true,
                });
        
                navigate("/dashboard")
              }}
            >
              Ir para o Dashboard
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default Step5;
