import React, { useContext, useState } from "react";

import iconWhats from "../../../../../assets/Vector (22).png";
import "./Step4.css";
import { Box, Modal } from "@mui/material";
import { useGoogleLogin } from "@react-oauth/google";
import { CheckContext } from "./CheckProvider";
import { toast } from "react-toastify";
import { AuthContext } from "../../../../../providers/AuthProvider";
import iconLOading from "../../../../../assets/Frame 11.png";
import api, { googleAuth } from "../../../../../utils/api";
import { useFormik } from "formik";
import * as yup from "yup";

const Step4 = () => {
  const { user, checkComplete, updatePartnerDetail } = useContext(AuthContext);
  const { setCurrentStep } = useContext(CheckContext);
  const [modal, setModal] = useState(false);
  const [loading, setLoading] = useState(false);

  const checkWpp = user?.wppConnected ? true : false;
  const checkGoogle = user?.connectedWithGoogle ? true : false;

  function isMobileDevice() {
    return window.innerWidth <= 768;
  }

  let width = isMobileDevice() ? 350 : 515;

  const formStep6 = useFormik({
    initialValues: {
      connectedWithGoogle: false,
    },

    validationSchema: yup.object({}),

    onSubmit: (values) => updateStep6(values),
  });

  const updateStep6 = async (values) => {
    try {
      if (user.partnerDetailId) {
        const { data } = await api.put(
          `partners/partner_details/${user.partnerDetailId}`,
          {
            data: {
              attributes: {
                connectedWithGoogle: values.connectedWithGoogle,
              },
            },
          }
        );

        if (data) {
          toast.success("Configurações atualizadas com sucesso!");
          await checkComplete(user.partnerDetailId);
          setCurrentStep("step5");
        }
      } else {
        const { data } = await api.post(`partners/partner_details/`, {
          data: {
            attributes: {
              partnerId: user.id,
              connectedWithGoogle: values.connectedWithGoogle,
            },
          },
        });

        if (data) {
          toast.success("Configurações atualizadas com sucesso!");
          updatePartnerDetail(data);
          await checkComplete(user.id);
          setCurrentStep("step5");
        }
      }
    } catch (error) {
      toast.error("Não foi possível atualizar as configurações.");
    }
  };

  const responseGoogle = async (response) => {
    setLoading(true);
    try {
      const { data } = await googleAuth.post("/callback", {
        code: response.code,
        partnerId: user.id,
      });
      if (data) {
        //console.log(data);
        toast.success("Sincronização de calendário realizada com sucesso!");
        if (user.partnerDetailId) {
          await checkComplete(user.partnerDetailId);
        } else {
          await checkComplete(user.id);
        }
        formStep6.setFieldValue("connectedWithGoogle", true);
        // navigate("/agendamento");
      }
    } catch (error) {
      //console.log(error);
      toast.error("Ocorreu um erro ao tentar sincronizar o calendário.");
    } finally {
      setCurrentStep("step5");
      setLoading(false);
    }
    //console.log(response);
  };

  const login = useGoogleLogin({
    onSuccess: (tokenResponse) => responseGoogle(tokenResponse),
    scope: ["https://www.googleapis.com/auth/calendar"],
    flow: "auth-code",
  });

  return (
    <>
      {loading ? (
        <div className="containerLoadingCheck">
          <div className="loadingDescription">
            <img src={iconLOading} alt="" />
            <div>Conectando...</div>
          </div>
        </div>
      ) : (
        <div className="containerStep4Check">
          <Modal
            open={modal}
            onClose={() => {
              setModal(false);
            }}
          >
            <Box
              style={{
                background: "linear-gradient(180deg, #f1fcf4 0%, #FFFFFF 100%)",
                borderRadius: "20px",
              }}
              sx={{
                position: "absolute",
                top: "50%",
                left: "50%",
                transform: "translate(-50%, -50%)",
                width: width,
                // height: 200,
                p: 2.5,
              }}
            ></Box>
          </Modal>
          <div className="title">WhatsApp Vinculado!</div>

          <div
            className="containerStatusCheck"
            style={{ marginBottom: "27px", display :'flex' }}
          >
            <Box
              sx={{
                cursor: "pointer",
                ":hover": {
                  opacity: "55%",
                },
              }}
              className={`${checkWpp}`}
            >
              <div className="cicle"></div>
              <div>WHATSAPP</div>
            </Box>

            <Box
              sx={{
                cursor: "pointer",
                ":hover": {
                  opacity: "55%",
                },
              }}
              className={`${checkGoogle}`}
            >
              <div className="cicle"></div>
              <div>CALENDÁRIO</div>
            </Box>
          </div>

          <div className="cardContent">
            <div>
              <strong>Facilite seus agendamentos!</strong>
            </div>
            <div>
              Vincule seu <strong>Google Calendar</strong> e deixe o assistente
              marcar tudo para você.
            </div>

            <div>
              Basta vincular com uma conta <strong>Google</strong> existente:
            </div>

            <button onClick={() => login()}>
              <img src={iconWhats} alt="" />
              Vincular Calendário Google
            </button>

          <button onClick={() => setCurrentStep('step3')} className="buttonBackCheck">
          Voltar
        </button>

            <div className="nextButton" onClick={() => setCurrentStep("step5")}>
              Quero vincular o calendário depois.
            </div>
          </div>

        </div>
      )}
    </>
  );
};

export default Step4;
