import React, { useState } from "react";
import ModalAlert from "../../../components/ModalAlert";
import { Typography } from "@mui/material";
import { toast } from "react-toastify";
import api from "../../../utils/api";

const DeleteModal = ({ item, getClients }) => {
  const [modalAlert, setModalAlert] = useState(false);

  function isMobileDevice() {
    return window.innerWidth <= 768;
  }

  const handleDelete = async (id) => {
    try {
      await api.delete(`partners/automatic_messages/${id}`);
      toast.success("disparo deletado com sucesso!");
      getClients();
    } catch (error) {
      if (error?.response?.data?.errors[0]?.title)
        toast.error(
          `Erro ao deletar! Erro - ${error.response.data.errors[0].title}`
        );
      else toast.error("Erro ao deletar!");
    }
  };

  return (
    <>
      <div onClick={() => setModalAlert(true)}>
        <div
          className="buttonMoreCheck"
          style={{
            backgroundColor: "transparent",
            border: "1px solid #fa460a",
          }}
        >
          DELETE
        </div>
      </div>

      <ModalAlert
        handleClose={() => setModalAlert(false)}
        handleSubmit={() => handleDelete(item.id)}
        open={modalAlert}
      >
        <Typography variant={isMobileDevice() ? "h7" : "h6"}>
          Tem certeza que deseja excluir esse disparo? Essa ação é irreversível.
        </Typography>
      </ModalAlert>
    </>
  );
};

export default DeleteModal;
