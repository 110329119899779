import React, { useContext } from "react";
import Step1 from "./Step1";
import Step2 from "./Step2";
import Step3 from "./Step3";
import Step4 from "./Step4";
import Step5 from "./Step5";

import { CheckContext } from "./CheckProvider";

import './CheckScreens.css';

const CheckScreens = () => {
  const { currentStep } = useContext(CheckContext);

  const renderContent = () => {
    switch (currentStep) {
      case "step2":
        return <Step2 />;
      case "step3":
        return <Step3 />;
      case "step4":
        return <Step4 />;
      case "step5":
        return <Step5 />;
      default:
        return <Step1 />;
    }
  };

  return <div className="containerCheckScreens">{renderContent()}</div>;
};

export default CheckScreens;
