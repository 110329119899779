import { InfoOutlined } from "@mui/icons-material";
import { InputAdornment, TextField, Tooltip, Typography } from "@mui/material";
import ReactInputMask from "react-input-mask";

export const InputWithLabel = ({
  label,
  value,
  form,
  disabled = false,

  validate = false,

  mask = "",
  maskChar = "",

  helperText = "",

  multiline = false,

  variant = "standard",

  ...props
}) => (
  <>
    {mask ? (
      <div className="containerInput" style={{ marginBottom: "20px" }}>
        <div className="title">{label}</div>
        <div className="subTitleInput">{label}</div>
        <ReactInputMask
          mask={mask}
          value={form.values[value]}
          onChange={(e) => form.setFieldValue(`${value}`, e.target.value)}
          onBlur={() => {
            form.setFieldTouched(`${value}`, true);
          }}
          maskChar={maskChar}
          disabled={disabled}
        >
          {() => (
            <input
              className="inputStyle"
              value={form.values[value]}
              multiline={multiline}
              minRows={4}
              maxRows={4}
              disabled={disabled}
              {...props}
            />
          )}
        </ReactInputMask>
      </div>
    ) : (
      <div className="containerInput" style={{ marginBottom: "20px" }}>
        <div className="title">{label}</div>
        <div
          className="subTitleInput"
          style={{ marginBottom: "10px", lineHeight: "10px" }}
        >
          {label}
        </div>
        {/* <TextField
          variant={variant}
          sx={{
            marginBottom: "25px",
          }}
          value={form.values[value]}
          onChange={(e) => form.setFieldValue(`${value}`, e.target.value)}
          onBlur={() => {
            form.setFieldTouched(`${value}`, true);
          }}
          multiline={multiline}
          minRows={4}
          maxRows={4}
          disabled={disabled}
          {...props}
        /> */}

        <input
          className="inputStyle"
          value={form.values[value]}
          onChange={(e) => form.setFieldValue(`${value}`, e.target.value)}
          onBlur={() => {
            form.setFieldTouched(`${value}`, true);
          }}
          multiline={multiline}
          minRows={4}
          maxRows={4}
          disabled={disabled}
          {...props}
        />
      </div>
    )}

    {validate && form.errors[value] && form.touched[value] && (
      <Typography
        style={{ marginBottom: "10px", marginTop: "-15px" }}
        color={"red"}
      >
        {form.errors[value]}
      </Typography>
    )}
  </>
);
