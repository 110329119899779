/* eslint-disable react-hooks/exhaustive-deps */
import React, { createContext, useContext, useState } from "react";
import { AuthContext } from "../../../../../providers/AuthProvider";
import api from "../../../../../utils/api";
import { toast } from "react-toastify";


export const CheckContext = createContext();

export const CheckProvider = ({ children }) => {
  const { user, checkComplete, updatePartnerDetail } = useContext(AuthContext);
    const [currentStep, setCurrentStep] = useState('step1');
    const [dataUpdate, setDataUpdate] = useState(null);

    const updateSettings = async () => {
      // setLoading(true);
  
      try {
        let body = {
          data: {
            attributes: {
              nameAttendant: dataUpdate?.nameAttendant,
              companyName: dataUpdate?.companyName,
              companyNiche: dataUpdate?.companyNiche,
              servedRegion: dataUpdate?.servedRegion,
              companyServices: dataUpdate?.companyServices?.join(", "),
              companyProducts: dataUpdate?.companyProducts?.join(", "),
              companyContact: dataUpdate.companyContact,
              // companyObjectives: dataUpdate?.companyObjectives?.map((item) => {
              //   if (item === "Outro") {
              //     return dataUpdate.companyObjectivesExtra;
              //   } else {
              //     return item;
              //   }
              // }),
              companyObjectives: [
                // dataUpdate?.companyObjectives?.agendar_reuniao && optionsArray[0],
                // dataUpdate?.companyObjectives?.adquirir_email && optionsArray[1],
                false,
                false,
                dataUpdate?.companyObjectives?.outro &&
                  dataUpdate?.companyObjectives?.outro,
              ]?.filter(Boolean),
              attendantInstructions: dataUpdate?.companyObjectives?.outro,
              // twitterXLink: dataUpdate.twitterXLink,
              // youtubeLink: dataUpdate.youtubeLink,
              // facebookLink: dataUpdate.facebookLink,
              // instagramLink: dataUpdate.instagramLink,
              key_differentials: dataUpdate?.keyDifferentials?.join(", "),
              // tone_voice: dataUpdate?.toneVoice?.map((item) => {
              //   if (item === "Outro") {
              //     return dataUpdate.toneVoiceExtra;
              //   } else {
              //     return item;
              //   }
              // }),
              // preferentialLanguage: dataUpdate.preferentialLanguage,
              // catalogLink: dataUpdate.catalogLink,
            },
          },
        };
  
        if (!user?.partnerDetailId) {
          body.data.attributes.partnerId = user.id;
        }
  
        const { data } = user?.partnerDetailId
          ? await api.put(
              `partners/partner_details/${user.partnerDetailId}`,
              body
            )
          : await api.post(`partners/partner_details/`, body);
  
        if (data) {
          // Adding it's token to all requests for now on.
          updatePartnerDetail(data);
          await checkComplete(user.id);
          toast.success("Configurações atualizadas com sucesso!");
          // navigate("/simulador");
        }
      } catch (error) {
        console.log(error);
        toast.error("Não foi possível atualizar as configurações.");
      } finally {
        // setLoading(false);
      }
    };


  return (
    <CheckContext.Provider
      value={{
        currentStep,
        setCurrentStep,
        dataUpdate,
        setDataUpdate,
        updateSettings,
      }}
    >
      {children}
    </CheckContext.Provider>
  );
};
