import React, { useContext, useState } from "react";
import "./Step1.css";
import { Box, Modal, RadioGroup } from "@mui/material";
import { FluxoAddProviderContext } from "./FluxoAddProvider";
import { toast } from "react-toastify";
import { AuthContext } from "../../../../providers/AuthProvider";
import iconLOading from "../../../../assets/Frame 11.png";
import { useFormik } from "formik";
import * as yup from "yup";
import {
  DateTimePicker,
  LocalizationProvider,
  TimePicker,
} from "@mui/x-date-pickers";
import { AdapterMoment } from "@mui/x-date-pickers/AdapterMoment";
import arrow from "../../../../assets/Vector.png";
import InputMask from "react-input-mask";

const Step1 = () => {
  const { user, checkComplete, updatePartnerDetail } = useContext(AuthContext);
  const {
    formSchedules,
    navigate,
    id,
    handleInput,
    insertParamIntoMessage,
    setCurrentStep,
    handleAddPhoneNumber,
    phoneNumbers,
    handleDeletePhoneNumber,
    loading,
    loadingCsv,
    handleFileChange,
    getCsv,
    saveLoading,
    handleInputParams,
    setPhone,
    phone,
  } = useContext(FluxoAddProviderContext);
  const [modal, setModal] = useState(false);

  return (
    <>
      {loading ? (
        <div className="containerLoadingCheck">
          <div className="loadingDescription">
            <img src={iconLOading} alt="" />
            <div>Conectando...</div>
          </div>
        </div>
      ) : (
        <div className="containerStep1Auto">
          {/* <div className="titlePageAuto">
          {id
            ? "Editar disparo de mensagens"
            : "Adicionar disparo de mensagens"}
        </div> */}
          <div className="cardContent">
            <div style={{ display: "flex", gap: "20px" }}>
              <div
                style={{
                  flex: 1,
                  display: "flex",
                  gap: "20px",
                  flexDirection: "column",
                }}
              >
                <div className="containerInputs">
                  <div className="card" style={{ width: "100%" }}>
                    <div
                      className="containerInput"
                      style={{ lineHeight: "1px" }}
                    >
                      <div className="title" style={{ marginBottom: "5px" }}>
                        Titulo
                      </div>
                      <div className="subTitleInput">
                        Insira o título da mensagem
                      </div>
                      <input
                        className="inputField"
                        type="text"
                        name="title"
                        onChange={handleInput}
                        onBlur={formSchedules.handleBlur}
                        value={formSchedules.values.title}
                      />
                      {formSchedules.touched.title &&
                      formSchedules.errors.title ? (
                        <div style={{ color: "red" }}>
                          {formSchedules.errors.title}
                        </div>
                      ) : null}
                    </div>
                  </div>
                </div>

                <div className="containerInput" style={{ lineHeight: "1px" }}>
                  <div className="title" style={{ marginBottom: "5px" }}>
                    Mensagem
                  </div>
                  <div className="subTitleInput">
                    Insira o conteudo da mensagem
                  </div>
                  <textarea
                    className="inputField"
                    type="text"
                    name="message"
                    onChange={handleInput}
                    onBlur={formSchedules.handleBlur}
                    value={formSchedules.values.message}
                    rows="7"
                    style={{ height: "100%" }}
                  />
                  {formSchedules.touched.message &&
                  formSchedules.errors.message ? (
                    <div style={{ color: "red" }}>
                      {formSchedules.errors.message}
                    </div>
                  ) : null}
                </div>
              </div>

              <div style={{ flex: 1 }}>
                <div className="containerInput" style={{ lineHeight: "1px" }}>
                  <div className="title" style={{ marginBottom: "5px" }}>
                    Parâmetros
                  </div>
                  <div className="subTitleInput">
                    {" "}
                    Adicione os parâmetros que farão parte do campo de mensagens
                  </div>
                  <div className="parametersInputsWrapper">
                    {[
                      { value: "NOME", disabled: true },
                      { value: "TELEFONE", disabled: true },
                      { name: "param1", placeholder: "Parâmetro 1" },
                      { name: "param2", placeholder: "Parâmetro 2" },
                      { name: "param3", placeholder: "Parâmetro 3" },
                      { name: "param4", placeholder: "Parâmetro 4" },
                      { name: "param5", placeholder: "Parâmetro 5" },
                    ].map((param, index) => (
                      <div key={index} className="inputGroupWrapper">
                        <input
                          className="inputField"
                          type="text"
                          name={param.name}
                          placeholder={param.placeholder}
                          value={
                            param.value || formSchedules.values[param.name]
                          }
                          disabled={param.disabled || false}
                          onChange={param.disabled ? undefined : handleInputParams}
                          onBlur={
                            param.disabled
                              ? undefined
                              : formSchedules.handleBlur
                          }
                        />
                        <button
                          className="addParameterButton"
                          style={{ width: "40px", height: "100%" }}
                          onClick={() =>
                            insertParamIntoMessage(
                              (param.disabled
                                ? param.value
                                : formSchedules.values[param.name] !== null ? formSchedules.values[param.name] : '' 
                              ).toUpperCase()
                            )
                          }
                        >
                          +
                        </button>
                      </div>
                    ))}
                  </div>
                </div>
              </div>
            </div>

            <div style={{ display: "flex", gap: "20px" }}>
              <button onClick={() => navigate(-1)} className="buttonBackCheck">
                Voltar
              </button>

              <button

               onClick={() => {
                formSchedules.handleSubmit();
                setCurrentStep('step2');
              }}
              
                className="buttonNextAUto"
              >
                {saveLoading ? (
                  "Carregando..."
                ) : (
                  <>
                    Próximo
                    {/* <img src={arrow} alt="arrow" /> */}
                  </>
                )}
              </button>
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default Step1;
