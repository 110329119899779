import React, { useState, useEffect, useContext, useRef } from "react";
import { AuthorizedLayout } from "../../../components/AuthorizedLayout/index";
import { toast } from "react-toastify";
import { Box, Button, Modal, Typography } from "@mui/material";
import { useLocation, useNavigate } from "react-router-dom";
import { AuthContext } from "../../../providers/AuthProvider";
import { useFormik } from "formik";
import * as yup from "yup";
import api from "../../../utils/api";
import Step1 from "./Components/Step1";
import Step3 from "./Components/Step3";
import Step4 from "./Components/Step4";
import "./BusinessSettings.css";

const optionsArray = [
  "Agendar uma reunião",
  "Adquirir email do cliente",
  "Outro",
];

const toneArray = [
  "Respeitoso & Empático",
  "Animado & Engajador",
  "Profissional & Firme",
  "Atencioso & Calmo",
  "Encorajador & Motivador",
  "Compassivo & Compreensivo",
  "Divertido & Cativante",
  "Cortês & Gentil",
  "Solidário & Amigável",
  "Eficiente & Rápido",
  "Confiável & Preciso",
  "Assertivo & Decisivo",
  "Perspicaz & Observador",
  "Outro",
];

const BusinessSettings = () => {
  const navigate = useNavigate();
  const { user, checkList, checkComplete, updatePartnerDetail, setCheckList } =
    useContext(AuthContext);
  const [loading, setLoading] = useState(true);
  const [saveLoading, setSaveLoading] = useState(false);
  const [checkSave, setCheckSave] = useState(true);
  const [modalWarning, setModalWarning] = useState(false);
  const [checkDataBasic, setDataBasic] = useState(false);
  const location = useLocation();
  const { value } = location.state || {};

  const [menuSelected, setMenuSelected] = useState(
    value || {
      menu: "Dados Básicos",
      index: 0,
    }
  );

  const [menu, setMenu] = useState();

  // Refs for scrolling to sections
  const section1Ref = useRef(null);
  const section2Ref = useRef(null);

  const scrollToSection1 = () => {
    section1Ref.current.scrollIntoView({ behavior: "smooth" });
  };

  const scrollToSection2 = () => {
    section2Ref.current.scrollIntoView({ behavior: "smooth" });
  };

  // Function to check if it's a mobile device
  function isMobileDevice() {
    return window.innerWidth <= 768;
  }

  // Fetch settings from API
  const getSettings = async () => {
    setLoading(true);
    try {
      if (user.partnerDetailId) {
        const { data } = await api.get(
          `partners/partner_details/${user.partnerDetailId}`
        );

        if (data) {
          let valorExtra = "";
          const valoresFiltrados = data.data?.attributes?.companyObjectives
            ? Object.keys(data.data.attributes.companyObjectives).reduce(
                (acc, key) => {
                  if (
                    optionsArray.includes(
                      data.data.attributes.companyObjectives[key]
                    )
                  ) {
                    acc[key] = data.data.attributes.companyObjectives[key];
                  } else {
                    acc[key] = "Outro";
                    valorExtra = data.data.attributes.companyObjectives[key];
                  }

                  return acc;
                },
                []
              )
            : [];

          let toneExtra = "";
          const toneVoiceFiltrados = data.data?.attributes?.toneVoice
            ? Object.keys(data.data.attributes.toneVoice).reduce((acc, key) => {
                if (toneArray.includes(data.data.attributes.toneVoice[key])) {
                  acc[key] = data.data.attributes.toneVoice[key];
                } else {
                  acc[key] = "Outro";
                  toneExtra = data.data.attributes.toneVoice[key];
                }

                return acc;
              }, [])
            : [];

          formStep1.setValues({
            nameAttendant: data.data.attributes?.nameAttendant,
            companyName: data.data.attributes?.companyName,
            companyNiche: data.data.attributes?.companyNiche,
            attendantInstructions:
              data.data.attributes?.attendantInstructions ?? valorExtra ?? "",
          });

          if (
            data.data.attributes?.nameAttendant &&
            data.data.attributes?.companyName &&
            data.data.attributes?.companyNiche &&
            (data.data.attributes?.attendantInstructions || valorExtra)
          ) {
            setDataBasic(true);
          }

          formStep3.setValues({
            companyServices: data.data.attributes?.companyServices,
            companyProducts: data.data.attributes?.companyProducts,
            catalogLink: data?.data?.attributes?.catalogLink || "",
            servedRegion: data.data.attributes?.servedRegion,
            keyDifferentials: data.data.attributes?.keyDifferentials,
            companyContact: data.data.attributes?.companyContact,
          });

          formStep4.setValues({
            companyObjectives: valoresFiltrados,
            companyObjectivesExtra:
              data.data.attributes?.attendantInstructions ?? valorExtra ?? "",
            marketingChannels: data.data.attributes?.marketingChannels,
            twitterXLink: data.data.attributes?.twitterXLink,
            youtubeLink: data.data.attributes?.youtubeLink,
            facebookLink: data.data.attributes?.facebookLink,
            instagramLink: data.data.attributes?.instagramLink,
            preferentialLanguage: data.data.attributes?.preferentialLanguage,
            toneVoice: toneVoiceFiltrados,
            toneVoiceExtra: toneExtra,
          });
        }
      }
    } catch (error) {
      toast.error("Não foi possível carregar as configurações.");
    } finally {
      setLoading(false);
      setCheckSave(true);
    }
  };

  useEffect(() => {
    getSettings();
  }, []);

  useEffect(() => {
    if (value) {
      setMenuSelected(value);
    }
  }, [value]);

  // Formik for Step 1 (Dados Básicos)
  const formStep1 = useFormik({
    initialValues: {
      nameAttendant: "",
      companyName: "",
      companyNiche: "",
      attendantInstructions: "",
    },

    validationSchema: yup.object({
      nameAttendant: yup
        .string()
        .required("O campo Nome do/a Atendente é obrigatório!")
        .min(3, "Nome deve ter pelo menos 3 caracteres"),
      companyName: yup
        .string()
        .required("O campo Nome Fantasia do Negócio é obrigatório!")
        .min(3, "Nome deve ter pelo menos 3 caracteres"),
      companyNiche: yup
        .string()
        .required("O campo Nicho do Negócio é obrigatório!")
        .min(3, "Nome deve ter pelo menos 3 caracteres"),
      attendantInstructions: yup
        .string()
        .required("O campo Instruções do Atendente é obrigatório!")
        .min(3, "Nome deve ter pelo menos 3 caracteres"),
    }),

    onSubmit: (values) => updateStep1(values),
  });

  const updateStep1 = async (values) => {
    setSaveLoading(true);
    try {
      if (user.partnerDetailId) {
        const { data } = await api.put(
          `partners/partner_details/${user.partnerDetailId}`,
          {
            data: {
              attributes: {
                nameAttendant: values.nameAttendant,
                companyName: values.companyName,
                companyNiche: values.companyNiche,
                attendantInstructions: values.attendantInstructions,
              },
            },
          }
        );

        if (data) {
          toast.success("Configurações atualizadas com sucesso!");
          getSettings();
          await checkComplete(user.partnerDetailId);
          handleNavigate();
          scrollToSection1();
        }
      } else {
        const { data } = await api.post(`partners/partner_details/`, {
          data: {
            attributes: {
              partnerId: user.id,
              nameAttendant: values.nameAttendant,
              companyName: values.companyName,
              companyNiche: values.companyNiche,
              attendantInstructions: values.attendantInstructions,
            },
          },
        });

        if (data) {
          toast.success("Configurações atualizadas com sucesso!");
          updatePartnerDetail(data);
          getSettings();
          await checkComplete(user.id);
          handleNavigate();
          scrollToSection1();
        }
      }
    } catch (error) {
      toast.error("Não foi possível atualizar as configurações.");
    } finally {
      setSaveLoading(false);
      setCheckSave(true);
    }
  };

  // Formik for Step 3 (Produtos e Serviços)
  const formStep3 = useFormik({
    initialValues: {
      companyServices: "",
      companyProducts: "",
      catalogLink: "",
      servedRegion: "",
      companyContact: "",
      keyDifferentials: "",
    },

    validationSchema: yup.object({}),

    onSubmit: (values) => updateStep3(values),
  });

  const updateStep3 = async (values) => {
    setSaveLoading(true);

    try {
      if (user.partnerDetailId) {
        const { data } = await api.put(
          `partners/partner_details/${user.partnerDetailId}`,
          {
            data: {
              attributes: {
                companyServices: values.companyServices,
                companyProducts: values.companyProducts,
                catalogLink: values.catalogLink,
                servedRegion: values.servedRegion,
                companyContact: values.companyContact,
                keyDifferentials: values.keyDifferentials,
              },
            },
          }
        );

        if (data) {
          toast.success("Configurações atualizadas com sucesso!");
          getSettings();
          await checkComplete(user.partnerDetailId);
          handleNavigate();
          scrollToSection2();
        }
      } else {
        const { data } = await api.post(`partners/partner_details/`, {
          data: {
            attributes: {
              partnerId: user.id,
              companyServices: values.companyServices,
              companyProducts: values.companyProducts,
              catalogLink: values.catalogLink,
              servedRegion: values.servedRegion,
              companyContact: values.companyContact,
              keyDifferentials: values.keyDifferentials,
            },
          },
        });

        if (data) {
          toast.success("Configurações atualizadas com sucesso!");
          updatePartnerDetail(data);
          getSettings();
          await checkComplete(user.id);
          handleNavigate();
          scrollToSection2();
        }
      }
    } catch (error) {
      toast.error("Não foi possível atualizar as configurações.");
    } finally {
      setSaveLoading(false);
      setCheckSave(true);
    }
  };

  // Formik for Step 4 (Estratégia de Atendimento)
  const formStep4 = useFormik({
    initialValues: {
      companyObjectives: [],
      companyObjectivesExtra: "",
      marketingChannels: "",
      twitterXLink: "",
      youtubeLink: "",
      facebookLink: "",
      instagramLink: "",
      toneVoice: [],
      toneVoiceExtra: "",
      preferentialLanguage: "",
    },

    validationSchema: yup.object({}),

    onSubmit: (values) => updateStep4(values),
  });

  const updateStep4 = async (values) => {
    setSaveLoading(true);

    try {
      if (user.partnerDetailId) {
        const { data } = await api.put(
          `partners/partner_details/${user.partnerDetailId}`,
          {
            data: {
              attributes: {
                companyObjectives: values?.companyObjectives?.filter(
                  (item) => item !== "Outro"
                ),
                marketingChannels: values.marketingChannels,
                twitterXLink: values.twitterXLink,
                youtubeLink: values.youtubeLink,
                facebookLink: values.facebookLink,
                instagramLink: values.instagramLink,
                toneVoice: values?.toneVoice?.map((item) => {
                  if (item === "Outro") {
                    return values.toneVoiceExtra;
                  } else {
                    return item;
                  }
                }),
                preferentialLanguage: values.preferentialLanguage,
              },
            },
          }
        );

        if (data) {
          toast.success("Configurações atualizadas com sucesso!");
          getSettings();
          await checkComplete(user.partnerDetailId);
          handleNavigate();
        }
      } else {
        const { data } = await api.post(`partners/partner_details/`, {
          data: {
            attributes: {
              partnerId: user.id,
              companyObjectives: values?.companyObjectives?.filter(
                (item) => item !== "Outro"
              ),
              marketingChannels: values.marketingChannels,
              twitterXLink: values.twitterXLink,
              youtubeLink: values.youtubeLink,
              facebookLink: values.facebookLink,
              instagramLink: values.instagramLink,
              toneVoice: values?.toneVoice?.map((item) => {
                if (item === "Outro") {
                  return values.toneVoiceExtra;
                } else {
                  return item;
                }
              }),
              preferentialLanguage: values.preferentialLanguage,
            },
          },
        });

        if (data) {
          toast.success("Configurações atualizadas com sucesso!");
          updatePartnerDetail(data);
          getSettings();
          await checkComplete(user.id);
          handleNavigate();
        }
      }
    } catch (error) {
      console.log(error);
      toast.error("Não foi possível atualizar as configurações.");
    } finally {
      setSaveLoading(false);
      setCheckSave(true);
    }
  };

  const handleNavigate = () => {
    switch (menuSelected.menu) {
      case "Dados Básicos":
        navigate("/configuracoes", {
          state: {
            value: {
              menu: "Produtos e Serviços",
              index: 1,
            },
          },
        });
        break;
      case "Produtos e Serviços":
        navigate("/configuracoes", {
          state: {
            value: {
              menu: "Estratégia de Atendimento",
              index: 2,
            },
          },
        });
        break;
      case "Estratégia de Atendimento":
        // Navigate to the next step or perform another action
        break;
      default:
        return null;
    }
  };

  const handleMenu = (item) => {
    if (
      item.menu !== "Dados Básicos" &&
      !checkList.partnerDetailsFilled &&
      !checkDataBasic
    ) {
      toast.error(
        "Atenção: preencha primeiro o nome do (a) atendente, o nome fantasia do negócio, o nicho do negócio e as instruções do atendente para ter acesso aos itens do menu!"
      );
    } else {
      if (!checkSave) {
        setModalWarning(true);
        setMenu(item);
      } else {
        setMenuSelected(item);
      }
    }
  };

  const nextPage = () => {
    setModalWarning(false);
    setMenuSelected(menu);
    setCheckSave(true);
  };

  const renderItemMenu = (name) => {
    switch (name) {
      case "Dados Básicos":
        return (
          <Step1
            form={formStep1}
            saveLoading={saveLoading}
            setMenuSelected={setMenuSelected}
            checkList={checkList}
            setCheckSave={setCheckSave}
            user={user}
            setCheckList={setCheckList}
          />
        );
      case "Produtos e Serviços":
        return (
          <Step3
            form={formStep3}
            saveLoading={saveLoading}
            setMenuSelected={setMenuSelected}
            checkList={checkList}
            setCheckSave={setCheckSave}
            user={user}
            setCheckList={setCheckList}
          />
        );
      case "Estratégia de Atendimento":
        return (
          <Step4
            form={formStep4}
            saveLoading={saveLoading}
            setMenuSelected={setMenuSelected}
            checkList={checkList}
            setCheckSave={setCheckSave}
            user={user}
            setCheckList={setCheckList}
          />
        );
      default:
        return null;
    }
  };

  const menuArray = [
    "Dados Básicos",
    "Produtos e Serviços",
    "Estratégia de Atendimento",
  ];

  return (
    <AuthorizedLayout>
      <div className="containerAttendant">
        <div className="containerMenu">
          {menuArray.map((item, index) => (
            <div
              key={index}
              className={`menu ${
                item === menuSelected.menu && "menuSelected"
              } ${
                item !== "Dados Básicos" &&
                !checkList.partnerDetailsFilled &&
                !checkDataBasic &&
                "disabledMenus"
              }`}
              onClick={() => handleMenu({ menu: item, index: index })}
            >
              {item}
            </div>
          ))}
        </div>

        <div className="contentWeb">{renderItemMenu(menuSelected.menu)}</div>

        {modalWarning && (
          <Modal
            open
            onClose={(event, reason) => {
              if (reason !== "backdropClick") {
                setModalWarning(false);
              }
            }}
          >
            <Box
              style={{
                background: "linear-gradient(180deg, #f1fcf4 0%, #FFFFFF 100%)",
                borderRadius: "20px",
              }}
              sx={{
                position: "absolute",
                top: "50%",
                left: "50%",
                transform: "translate(-50%, -50%)",
                width: isMobileDevice() ? 350 : 700,
                p: 2.5,
              }}
            >
              <div>
                <div style={{ display: "flex", flexDirection: "column" }}>
                  <Typography
                    sx={{
                      margin: 0,
                      fontFamily: [
                        '"Roboto"',
                        '"Helvetica"',
                        '"Arial"',
                        "sans-serif",
                      ],
                      fontWeight: 500,
                      fontSize: "1.25rem",
                      lineHeight: 1.6,
                      letterSpacing: "0.0075em",
                      alignSelf: "flex-start",
                      marginTop: "15px",
                      marginBottom: "15px",
                      textAlign: "center",
                      width: "100%",
                    }}
                  >
                    <Typography
                      fontSize={28}
                      fontWeight={600}
                      color={"#e52207"}
                    >
                      AVISO
                    </Typography>
                  </Typography>

                  <Typography
                    textAlign={"center"}
                    fontWeight={"600"}
                    style={{ marginBottom: "25px" }}
                    fontSize={20}
                  >
                    {
                      "Você tem alterações não salvas. Se você sair sem salvar, perderá todas as alterações feitas."
                    }
                  </Typography>
                  <div
                    style={{
                      display: "flex",
                      alignItems: "center",
                      flexWrap: "wrap",
                      justifyContent: "center",
                      width: "100%",
                    }}
                  >
                    <Button
                      sx={{
                        height: "35px",
                        width: "140px",
                        p: 2,
                        m: 1,
                        alignSelf: "center",
                      }}
                      variant="contained"
                      disabled={saveLoading}
                      onClick={() => {
                        setModalWarning(false);
                      }}
                    >
                      <Typography color={"white"} variant={"h6"}>
                        Voltar
                      </Typography>
                    </Button>

                    <Button
                      sx={{
                        height: "35px",
                        width: "140px",
                        p: 2,
                        m: 1,
                        alignSelf: "center",
                        backgroundColor: "#e52207",
                      }}
                      variant="contained"
                      disabled={saveLoading}
                      onClick={() => {
                        nextPage();
                      }}
                    >
                      <Typography color={"white"} variant={"h6"}>
                        {"Entendi"}
                      </Typography>
                    </Button>
                  </div>
                </div>
              </div>
            </Box>
          </Modal>
        )}

        <div className="contentMobile">
          <Step1
            form={formStep1}
            saveLoading={saveLoading}
            setMenuSelected={setMenuSelected}
            setCheckSave={setCheckSave}
            checkList={checkList}
            user={user}
            setCheckList={setCheckList}
          />

          {checkList.partnerDetailsFilled && user.partnerDetailsFilled ? (
            <>
              <Step3
                form={formStep3}
                saveLoading={saveLoading}
                setMenuSelected={setMenuSelected}
                section1Ref={section1Ref}
                setCheckSave={setCheckSave}
                checkList={checkList}
                user={user}
                setCheckList={setCheckList}
              />

              <Step4
                form={formStep4}
                saveLoading={saveLoading}
                setMenuSelected={setMenuSelected}
                section2Ref={section2Ref}
                setCheckSave={setCheckSave}
                checkList={checkList}
                user={user}
                setCheckList={setCheckList}
              />
            </>
          ) : (
            <div className="containerInfo">
              <strong>Atenção:</strong>
              <div>
                Preencha primeiro o nome do (a) atendente, o nome fantasia do
                negócio e o nicho do negócio para ter acesso a todas as
                configurações!
              </div>
            </div>
          )}
        </div>
      </div>
    </AuthorizedLayout>
  );
};

export default BusinessSettings;
