import React, { useContext, useEffect, useState } from "react";
import ContentContainer from "./ContentContainer/ContentContainer";
import api from "../../utils/api";
import { toast } from "react-toastify";
import { AuthContext } from "../../providers/AuthProvider";
import Sidebar from "../MainSidebar/Sidebar";
import TopBar from "../MainTopbar/TopBar";

import ondas from "../../assets/fuundo_ondas.png";
import logo from "../../assets/logo-sacgpt.png";
import { useLocation } from "react-router-dom";

import "./AuthorizedLayout.css";

export const AuthorizedLayout = ({ children, disableHUD = false }) => {
  const [mainSidebarVisible, setMainSidebarVisible] = useState(false);
  const [notificationsVisible, setNotificationsVisible] = useState(false);
  const [supportModal, setSupportModal] = useState(false);
  const { pathname } = useLocation();

  const [loading, setLoading] = useState(true);
  const [notifications, setNotifications] = useState({});
  const [menuOpen, setMenuOpen] = useState(false);

  const toggleMenu = () => {
    setMenuOpen(!menuOpen);
  };

  const { user, checkList } = useContext(AuthContext);

  useEffect(() => {
    if (user.userType === "partner") {
      getNotifications();
    } else {
      setLoading(false);
    }
  }, []);

  const getNotifications = async () => {
    setLoading(true);
    try {
      const { data } = await api.get("partners/notifications");

      if (data) {
        //console.log(data);
        setNotifications(
          data.data
            .map((item) => {
              return {
                notificationId: item?.id,
                id: item?.attributes?.metadata?.partner_client?.id,
                clientName: item?.attributes?.metadata?.partner_client?.name,
                readed: item?.attributes?.readed,
                title: item?.attributes?.title,
              };
            })
            .sort((a, b) => {
              return b.id - a.id;
            })
        );
      }
    } catch (error) {
      //console.log(error);
      toast.error("Não foi possível carregar a lista de notificações.");
    } finally {
      setLoading(false);
    }
  };

  return (
    <div
      className="containerAll"
      style={{ display: "flex", overflow: "hidden" }}
    >
      <img src={ondas} alt="ondas" className="ondas" />
      {!disableHUD && <Sidebar menuOpen={menuOpen} toggleMenu={toggleMenu} />}

      <div className="main-content">
        {checkList.partnerDetailsFilled &&
          checkList.wizard &&
          pathname !== "/configuracoes/automatica" && (
            <div className="containerHeaderMobile">
              <img
                src={logo}
                alt={""}
                style={{ width: "150px", margin: "0px 20px" }}
              />
              <div
                className={`menu-icon ${menuOpen && "active"}`}
                onClick={toggleMenu}
              >
                <div className="btn">
                  <div className="btn-left"></div>
                  <div className="btn-right"></div>
                </div>
              </div>
            </div>
          )}
        {/* <TopBar
          notificationsVisible={notificationsVisible}
          setNotificationsVisible={setNotificationsVisible}
          notifications={notifications}
        /> */}
        <ContentContainer
          notificationsVisible={notificationsVisible}
          setNotificationsVisible={setNotificationsVisible}
          loading={loading}
          notifications={notifications}
          setNotifications={setNotifications}
        >
          <>
            {!disableHUD && (
              <TopBar
                notificationsVisible={notificationsVisible}
                setNotificationsVisible={setNotificationsVisible}
                notifications={notifications}
              />
            )}

            {children}

            {checkList.partnerDetailsFilled &&
              checkList.wizard &&
              pathname !== "/configuracoes/automatica" && (
                <footer className="footerApp mobile">
                  sacgpt.com.br © 2024 - Todos os Direitos Reservados
                </footer>
              )}
          </>
        </ContentContainer>

        <footer className="footerApp web">
          sacgpt.com.br © 2024 - Todos os Direitos Reservados
        </footer>
      </div>
    </div>
  );
};
