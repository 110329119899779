import {
  AttachMoney,
  Home,
  Person,
  Settings,
  Store,
  EventAvailable,
  ScheduleSend,
  ContactMail,
  AppSettingsAlt,
} from "@mui/icons-material";
import React, { useContext, useEffect, useState } from "react";
import { AuthContext } from "../../providers/AuthProvider";
import { SidebarLink } from "./styles";
import { useLocation } from "react-router-dom";

import wallet from "../../assets/Wallet.png";
import userP from "../../assets/User.png";
import users from "../../assets/Users.png";
import headset from "../../assets/Headset.png";
import diamonds from "../../assets/DiamondsFour.png";
import question from "../../assets/Question.png";

import iconAtendente from "../../assets/icon-atendente.png"; // Ícone para o menu Atendente
import expandLessIcon from "../../assets/expand-less.png"; // Ícone para recolher
import expandMoreIcon from "../../assets/expand-more.png"; // Ícone para expandir

import "./Sidebar.css";

export const MainListItems = () => {
  const { user, checkList } = useContext(AuthContext);
  const { pathname } = useLocation();

  const color = "#77E191";

  const checkPathname = (route) => {
    if (
      route === "/configuracoes" ||
      route === "/simulador" ||
      route === "/vincular" ||
      route === "/configuracoes/automatica"
    ) {
      return true;
    } else {
      return false;
    }
  };

  const [openAtendente, setOpenAtendente] = useState(checkPathname(pathname));

  const handleClickAtendente = () => {
    setOpenAtendente(!openAtendente);
  };

  const ListedItem = ({ icon, route, title }) => {
    return (
      <SidebarLink to={route}>
        <div
          className={`containerMenuLink ${
            pathname.includes(route) && "selectedMenu"
          }`}
        >
          {icon}
          <div className="textMenu">{title}</div>
        </div>
      </SidebarLink>
    );
  };

  if (user.userType === "admin") {
    return (
      <div>
        <ListedItem
          route={"/admin/parceiros"}
          title={"Parceiros"}
          icon={<Store className="imgMenu" sx={{ color: color }} />}
        />

        <ListedItem
          route={"/admin/financeiro"}
          title={"Financeiro"}
          icon={<AttachMoney className="imgMenu" sx={{ color: color }} />}
        />

        <ListedItem
          route={"/admin/afiliados"}
          title={"Afiliados"}
          icon={<Person className="imgMenu" sx={{ color: color }} />}
        />
      </div>
    );
  } else if (user.userType === "partner") {
    return (
      <div>
        <SidebarLink to={"/dashboard"}>
          <div
            className={`containerMenuLink ${
              pathname === "/dashboard" && "selectedMenu"
            }`}
          >
            <img src={diamonds} alt="Dashboard" className="imgMenu" />
            <div className="textMenu">Dashboard</div>
          </div>
        </SidebarLink>

        {/* Clientes */}
        {checkList.partnerDetailsFilled && (
          <SidebarLink to={"/clientes"}>
            <div
              className={`containerMenuLink ${
                pathname === "/clientes" && "selectedMenu"
              }`}
            >
              <img src={users} alt="Clientes" className="imgMenu" />
              <div className="textMenu">Clientes</div>
            </div>
          </SidebarLink>
        )}

        {/* Menu Atendente */}
        <SidebarLink onClick={handleClickAtendente}>
          <div
            className={`containerMenuLink ${
              checkPathname(pathname) && "selectedMenu"
            }`}
          >
            <img src={iconAtendente} alt="Atendente" className="imgMenu" />
            <div className="textMenu">Atendente</div>
            <img
              src={openAtendente ? expandLessIcon : expandMoreIcon}
              alt="Expandir"
              className="expandIcon"
              style={{ alignSelf: "center" }}
            />
          </div>
        </SidebarLink>

        {/* Subitens do menu Atendente */}
        {openAtendente && (
          <div className="subMenuContainer">
            <SidebarLink to="/configuracoes">
              <div
                className={`containerMenuLink subMenuLink ${
                  (pathname === "/configuracoes" ||
                    pathname === "/configuracoes/automatica") &&
                  "selectedMenu"
                }`}
              >
                <div className="textMenu">Configurações</div>
              </div>
            </SidebarLink>

            <SidebarLink to="/vincular">
              <div
                className={`containerMenuLink subMenuLink ${
                  pathname === "/vincular" && "selectedMenu"
                }`}
              >
                <div className="textMenu">Vincular Contas</div>
              </div>
            </SidebarLink>

            {checkList.partnerDetailsFilled && (
              <SidebarLink to="/simulador">
                <div
                  className={`containerMenuLink subMenuLink ${
                    pathname === "/simulador" && "selectedMenu"
                  }`}
                >
                  <div className="textMenu">Simulador</div>
                </div>
              </SidebarLink>
            )}
          </div>
        )}

        {/* <div className="lineMenu"></div> */}

        {/* Perfil */}
        <SidebarLink to={"/conta"}>
          <div
            className={`containerMenuLink ${
              pathname === "/conta" && "selectedMenu"
            }`}
          >
            <img src={userP} alt="Perfil" className="imgMenu" />
            <div className="textMenu">Perfil</div>
          </div>
        </SidebarLink>

        {/* Financeiro */}
        {checkList.partnerDetailsFilled && (
          <SidebarLink to={"/financeiro"}>
            <div
              className={`containerMenuLink ${
                pathname.includes("/financeiro") && "selectedMenu"
              }`}
            >
              <img src={wallet} alt="Financeiro" className="imgMenu" />
              <div className="textMenu">Financeiro</div>
            </div>
          </SidebarLink>
        )}

        {/* Agendamento */}
        {checkList.partnerDetailsFilled && (
          <SidebarLink to={"/agendamento"}>
            <div
              className={`containerMenuLink ${
                pathname === "/agendamento" && "selectedMenu"
              }`}
            >
              <EventAvailable sx={{ color: color }} className="imgMenu" />
              <div className="textMenu">Agendamento</div>
            </div>
          </SidebarLink>
        )}
        {/* 
        <SidebarLink to={"/auto-mensagens"}>
          <div
            className={`containerMenuLink ${
              pathname === "/auto-mensagens" && "selectedMenu"
            }`}
          >
            <ScheduleSend sx={{ color: color }} className="imgMenu" />

            <div className="textMenu">Auto Mensagens</div>
          </div>
        </SidebarLink> */}
      </div>
    );
  } else if (user.userType === "sales") {
    return (
      <div>
        <ListedItem
          route={"/vendas/dashboard"}
          title={"Dashboard"}
          icon={<Home className="imgMenu" sx={{ color: color }} />}
        />

        <ListedItem
          route={"/vendas/crm"}
          title={"CRM"}
          icon={<ContactMail className="imgMenu" sx={{ color: color }} />}
        />

        <ListedItem
          route={"/vendas/demo"}
          title={"Demonstração"}
          icon={<AppSettingsAlt className="imgMenu" sx={{ color: color }} />}
        />

        <ListedItem
          route={"/vendas/clientes"}
          title={"Clientes"}
          icon={<Person className="imgMenu" sx={{ color: color }} />}
        />

        <ListedItem
          route={"/vendas/financeiro"}
          title={"Financeiro"}
          icon={<AttachMoney className="imgMenu" sx={{ color: color }} />}
        />

        <ListedItem
          route={"/vendas/conta"}
          title={"Conta"}
          icon={<Settings className="imgMenu" sx={{ color: color }} />}
        />
      </div>
    );
  } else {
    return null;
  }
};
