import { Box, Button, Modal, Typography } from "@mui/material";
import React, { useContext, useState } from "react";
import api from "../../../../../utils/api";
import { toast } from "react-toastify";
import { faker } from "@faker-js/faker";
import { AuthContext } from "../../../../../providers/AuthProvider";
import { useNavigate } from "react-router-dom";
import arrowIcon from "../../../../../assets/Vector (20).png";
import iconLOading from "../../../../../assets/Frame 11.png";

import "./Step1.css";
import { CheckContext } from "./CheckProvider";

const Step1 = () => {
  const { user, checkComplete, updatePartnerDetail } = useContext(AuthContext);
  const { setCurrentStep, setDataUpdate } = useContext(CheckContext);
  const navigate = useNavigate();
  const [websiteUrl, setWebsiteUrl] = useState("");
  const [companyDescription, setCompanyDescription] = useState("");
  const [isUrlSelected, setIsUrlSelected] = useState(true);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState("");
  const [modalWarning, setModalWarning] = useState(false);

  const handleOptionChange = (e) => {
    setIsUrlSelected(e.target.value === "url");
    setError("");
  };

  const optionsArray = [
    "Agendar uma reunião",
    "Adquirir email do cliente",
    "Outro",
  ];

  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);
    setError("");

    try {
      if (isUrlSelected) {
        if (!websiteUrl) {
          setError("Por favor, insira a URL do seu site.");
          setLoading(false);
          return;
        }
        // Enviar a URL para o serviço de terceiros
        const { data } = await api.post(
          "https://primary-production-5d91.up.railway.app/webhook/atendente",
          {
            url: handleUrl(websiteUrl),
          }
        );

        if (data?.analysisResult) {
          // Supondo que a resposta contenha as informações necessárias para a configuração
          await checkComplete(user.id);
          // Redirecionar para a tela de chat de demonstração
          console.log("Resposta do serviço:", data?.analysisResult);

          updateSettings({
            companyName: data?.analysisResult?.nome_fantasia,
            companyNiche: data?.analysisResult?.nicho,
            companyProducts: data?.analysisResult?.produtos,
            companyServices: data?.analysisResult?.servicos,
            servedRegion: data?.analysisResult?.campo_atuacao,
            keyDifferentials: data?.analysisResult?.diferenciais,
            nameAttendant: faker.person.firstName(),
            companyObjectives: data?.analysisResult?.objetivos,
            companyObjectivesExtra: data?.analysisResult?.objetivos?.outro,
            companyContact: handleUrl(websiteUrl),
          });
          setDataUpdate({
            companyName: data?.analysisResult?.nome_fantasia,
            companyNiche: data?.analysisResult?.nicho,
            companyProducts: data?.analysisResult?.produtos,
            companyServices: data?.analysisResult?.servicos,
            servedRegion: data?.analysisResult?.campo_atuacao,
            keyDifferentials: data?.analysisResult?.diferenciais,
            nameAttendant: faker.person.firstName(),
            companyObjectives: data?.analysisResult?.objetivos,
            companyObjectivesExtra: data?.analysisResult?.objetivos?.outro,
          });
          setCurrentStep("step2");
        } else {
          setError("Falha ao configurar o bot. Por favor, tente novamente.");
          console.log("Resposta do serviço:", data);
        }
      } else {
        if (!companyDescription.trim()) {
          setError("Por favor, insira a descrição da sua empresa.");
          setLoading(false);
          return;
        }

        // Enviar a descrição para outro serviço de terceiros
        const { data } = await api.post(
          "https://primary-production-5d91.up.railway.app/webhook/atendente-text",
          {
            description: companyDescription.trim(),
          }
        );

        if (data?.analysisResult) {
          // Supondo que a resposta contenha as informações necessárias para a configuração
          await checkComplete(user.id);
          // Redirecionar para a tela de chat de demonstração
          console.log("Resposta do serviço:", data?.analysisResult);

          if (!data?.analysisResult?.nome_fantasia) {
            setLoading(false);
            setError("Falha ao configurar o bot. Por favor, adicione o nome do seu negócio na descrição.");
            setModalWarning(true);
          } else if (!data?.analysisResult?.nicho) {
            setLoading(false);
            setError("Falha ao configurar o bot. Por favor, adicione o nicho do seu negócio na descrição.");
            setModalWarning(true);
          } else if (data?.analysisResult?.nome_fantasia && data?.analysisResult?.nicho) {
            updateSettings({
              companyName: data?.analysisResult?.nome_fantasia,
              companyNiche: data?.analysisResult?.nicho,
              companyProducts: data?.analysisResult?.produtos,
              companyServices: data?.analysisResult?.servicos,
              servedRegion: data?.analysisResult?.campo_atuacao,
              keyDifferentials: data?.analysisResult?.diferenciais,
              nameAttendant: faker.person.firstName(),
              companyObjectives: data?.analysisResult?.objetivos,
              companyObjectivesExtra: data?.analysisResult?.objetivos?.outro,
            });
            setDataUpdate({
              companyName: data?.analysisResult?.nome_fantasia,
              companyNiche: data?.analysisResult?.nicho,
              companyProducts: data?.analysisResult?.produtos,
              companyServices: data?.analysisResult?.servicos,
              servedRegion: data?.analysisResult?.campo_atuacao,
              keyDifferentials: data?.analysisResult?.diferenciais,
              nameAttendant: faker.person.firstName(),
              companyObjectives: data?.analysisResult?.objetivos,
              companyObjectivesExtra: data?.analysisResult?.objetivos?.outro,
            });
            setCurrentStep("step2");
          } else {
            setError("Por favor, elabore um descritivo mais detalhado a respeito de sua empresa ou serviço.")
            setModalWarning(true);
          }
        } else {
          setError("Falha ao configurar o bot. Por favor, tente novamente.");
          console.log("Resposta do serviço:", data);
        }
      }
    } catch (err) {
      // Registrar o erro no console
      console.error("Erro ao configurar o bot:", err);
      setError("Ocorreu um erro. Por favor, tente novamente.");
      setLoading(false);
    } finally {
    }
  };

  const updateSettings = async (values) => {
    setLoading(true);

    try {
      let body = {
        data: {
          attributes: {
            nameAttendant: values?.nameAttendant,
            companyName: values?.companyName,
            companyNiche: values?.companyNiche,
            servedRegion: values?.servedRegion,
            companyServices: values?.companyServices?.join(", "),
            companyProducts: values?.companyProducts?.join(", "),
            companyContact: values.companyContact,
            // companyObjectives: values?.companyObjectives?.map((item) => {
            //   if (item === "Outro") {
            //     return values.companyObjectivesExtra;
            //   } else {
            //     return item;
            //   }
            // }),
            companyObjectives: [
              // values?.companyObjectives?.agendar_reuniao && optionsArray[0],
              // values?.companyObjectives?.adquirir_email && optionsArray[1],
              false,
              false,
              values?.companyObjectives?.outro &&
                values?.companyObjectives?.outro,
            ]?.filter(Boolean),
            attendantInstructions: values?.companyObjectives?.outro,
            // twitterXLink: values.twitterXLink,
            // youtubeLink: values.youtubeLink,
            // facebookLink: values.facebookLink,
            // instagramLink: values.instagramLink,
            key_differentials: values?.keyDifferentials?.join(", "),
            // tone_voice: values?.toneVoice?.map((item) => {
            //   if (item === "Outro") {
            //     return values.toneVoiceExtra;
            //   } else {
            //     return item;
            //   }
            // }),
            // preferentialLanguage: values.preferentialLanguage,
            // catalogLink: values.catalogLink,
          },
        },
      };

      if (!user?.partnerDetailId) {
        body.data.attributes.partnerId = user.id;
      }

      const { data } = user?.partnerDetailId
        ? await api.put(
            `partners/partner_details/${user.partnerDetailId}`,
            body
          )
        : await api.post(`partners/partner_details/`, body);

      if (data) {
        updatePartnerDetail(data);
      }
    } catch (error) {
      console.log(error);
      toast.error("Não foi possível atualizar as configurações.");
    } finally {
      setLoading(false);
    }
  };

  const handleUrl = (value) => {
    if (value.startsWith("http://") || value.startsWith("https://")) {
      return value;
    } else {
      return "http://" + value;
    }
  };

  function isMobileDevice() {
    return window.innerWidth <= 768;
  }
  return (
    <>
      {modalWarning && (
        <Modal
          open
          onClose={(event, reason) => {
            if (reason !== "backdropClick") {
              setModalWarning(false);
            }
          }}
        >
          <Box
            style={{
              background: "linear-gradient(180deg, #f1fcf4 0%, #FFFFFF 100%)",
              borderRadius: "20px",
            }}
            sx={{
              position: "absolute",
              top: "50%",
              left: "50%",
              transform: "translate(-50%, -50%)",
              width: isMobileDevice() ? 350 : 700,
              p: 2.5,
            }}
          >
            <div>
              <div style={{ display: "flex", flexDirection: "column" }}>
                <Typography
                  sx={{
                    margin: 0,
                    fontFamily: [
                      '"Roboto"',
                      '"Helvetica"',
                      '"Arial"',
                      "sans-serif",
                    ],
                    fontWeight: 500,
                    fontSize: "1.25rem",
                    lineHeight: 1.6,
                    letterSpacing: "0.0075em",
                    alignSelf: "flex-start",
                    marginTop: "15px",
                    marginBottom: "15px",
                    textAlign: "center",
                    width: "100%",
                  }}
                >
                  <Typography fontSize={28} fontWeight={600} color={"red"}>
                    ATENÇÃO
                  </Typography>
                </Typography>

                <Typography
                  textAlign={"center"}
                  fontWeight={"600"}
                  style={{ marginBottom: "25px" }}
                  fontSize={20}
                >
                  {"Descrição insuficiente"}
                </Typography>
                <Typography
                  textAlign={"center"}
                  style={{ marginBottom: "25px" }}
                  fontSize={18}
                >
                  {/* {
                    "Por favor, elabore um descritivo mais detalhado a respeito de sua empresa ou serviço."
                  } */}
                  {error}
                </Typography>

                <Button
                  sx={{
                    height: "35px",
                    p: 2,
                    m: 1,
                    alignSelf: "center",
                  }}
                  variant="contained"
                  onClick={() => {
                    setModalWarning(false);
                  }}
                >
                  <Typography color={"white"} variant={"h6"}>
                    {"OK, ENTENDI"}
                  </Typography>
                </Button>
              </div>
            </div>
          </Box>
        </Modal>
      )}
      {loading ? (
        <div className="containerLoadingCheck">
          <div className="loadingDescription">
            <img src={iconLOading} alt="" />
            <div>Estamos criando seu assistente...</div>
          </div>
        </div>
      ) : (
        <div className="containerStep1Check">
          <div className="containerHeader">
            {`Olá, ${user.name}! Vamos começar?`}
          </div>

          <form
            onSubmit={handleSubmit}
            className="containerCardDescription"
            style={{ height: isUrlSelected ? "340px" : "670px" }}
          >
            <div className="titleCheck">
              {isUrlSelected ? "Website" : "Descrição da sua empresa"}
            </div>
            <div className="subtitleCheck">
              {isUrlSelected
                ? "Insira o website da sua empresa para otimizar o atendimento ao cliente."
                : "Nos conte um pouco sobre sua empresa e que tipo de suporte você precisa."}
            </div>
            {isUrlSelected ? (
              <input
                // type="url"
                value={websiteUrl}
                onChange={(e) => setWebsiteUrl(e.target.value)}
                required
                className="urlInput"
              />
            ) : (
              <textarea
                placeholder="Digite a descrição da sua empresa"
                value={companyDescription}
                onChange={(e) => setCompanyDescription(e.target.value)}
                required
                className="descriptionInput"
                // rows="4"
              />
            )}

            <button type="submit" disabled={loading} className="submitButton">
              {loading ? "Processando..." : "Criar meu assistente"}
              <img src={arrowIcon} alt="arrow icon" />
            </button>

            {isUrlSelected ? (
              <div
                onClick={() => setIsUrlSelected(false)}
                className="buttonTypeINput"
              >
                Prefiro escrever uma descrição
              </div>
            ) : (
              <div
                onClick={() => setIsUrlSelected(true)}
                className="buttonTypeINput"
              >
                Prefiro enviar meu site
              </div>
            )}
          </form>
        </div>
      )}
    </>
  );
};

export default Step1;
