import React, { useContext } from "react";
import { AuthorizedLayout } from "../../../../components/AuthorizedLayout";
import Step1 from "./Step1";
import Step2 from "./Step2";

import { FluxoAddProviderContext } from "./FluxoAddProvider";

const FluxoAdd = () => {
  const { currentStep, id } = useContext(FluxoAddProviderContext);

  const renderContent = () => {
    switch (currentStep) {
      case "step2":
        return <Step2 />;
      // case "step3":
      //   return <Step3 />;
      // case "step4":
      //   return <Step4 />;
      // case "step5":
      //   return <Step5 />;
      default:
        return <Step1 />;
    }
  };

  return (
    <AuthorizedLayout>
    <div className="containerFluxoAdd">{renderContent()}</div>
  </AuthorizedLayout>
  );
};

export default FluxoAdd;
